<template>
  <div>
    <el-menu
      background-color="#1a2031"
      text-color="#fff"
      active-text-color="#4d85d9"
      :default-active='String(defaultActive)'
      @select="changeTarget"
    >
        <el-submenu index="sell" v-if="sell.length">
          <template slot="title">
            <span>销售产品</span>
          </template>
          <el-menu-item v-for="(item,index) in sell" :key="index" :index="String(item.productId)">{{item.productName}}</el-menu-item>
        </el-submenu>
        <el-submenu index="buy" v-if="buy.length">
          <template slot="title">
            <span>采购产品</span>
          </template>
          <el-menu-item v-for="(item,index) in buy" :key="index" :index="String(item.productId)">{{item.productName}}</el-menu-item>
        </el-submenu>
        <el-menu-item v-for="(item,index) in other" :key="index" :index="String(item.productId)">{{item.productName}}</el-menu-item>
    </el-menu>
  </div>
</template>

<script>
export default {
  name: 'ProductsSide',
  props:{
    nav:{
      type:Array,
      default:()=>{
        return [{productName:'环氧氯丙烷',productId:12636,targetId:1,group:"sell"},
        {productName:'氯乙烯',productId:12993,targetId:9,group:"sell"},
        {productName:'液碱',productId:12356,targetId:3,group:"sell"},
        {productName:'片碱',productId:12354,targetId:4,group:"sell"},
        {productName:'环氧丙烷',productId:12602,targetId:2,group:"sell"}]
      }
    },
    defaultActive:{
      type:[String,Number],
      default:''
    }
  },
  data() {
    return {

    }
  },
  computed:{
    sell(){
      return this.nav.filter(v=>{return v.group=="sell"})
    },
    buy(){
      return this.nav.filter(v=>{return v.group=="buy"})
    },
    other(){
      return this.nav.filter(v=>{return v.group!="sell"&&v.group!="buy"})
    }
  },
  methods:{
    changeTarget(index){
      // console.log(index,indexPath)
      this.$emit('change',this.nav.find(v=>{return v.productId==index}))
    }
  },
  created(){
   
  },
  mounted(){
    
  },
  destroyed(){
   
  },
  watch: {

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>

  
</style>
