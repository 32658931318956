<template>
    <div class="page-wrap" v-loading="loading">
      <div class="page-aside">
       <ProductsSide @change="changeProduct" :nav="productList" :defaultActive="curProduct.productId"></ProductsSide>
      </div>
      <div class="page-main flex-column">
        <SubNav :nav="subNav" :router="true"></SubNav>
        <div class="main padding-20"><router-view :curProduct='curProduct'></router-view></div>
        <el-backtop target=".main">
          <i class="el-icon-top"></i>
        </el-backtop>
      </div>  
    </div> 
</template>

<script>
import ProductsSide from '@/components/ProductsSide.vue'
import SubNav from '@/components/SubNav.vue'
export default {
  name: 'Entry',
  components: { ProductsSide,SubNav },
  data() {
    return {
     subNav:[{name:'周度预测模型',value:'/expert/assignment/week'},{name:'月度预测模型',value:'/expert/assignment/month'}],
     curProduct:{},
     productList:[],
     loading:false,
    }
  },
  created(){
    this.getProducts()
  },
  methods:{
    getProducts(){
      this.loading=true
      let request=this.$instance.get('/manage/getProductPower',{
          params: {
           ...this.$store.state.basicParams,
           moduleCode:'zhuan_jia_fu_zhi'
          },
      })
      request.then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.productList=res.data.info
          this.checkProAuth()
        }else{
          this.$router.replace({
            path:'/error',
            query:{
              redirect:this.$route.fullPath
            }
          })
        }
      })
      request.catch(()=>{
        this.loading=false
        this.$router.replace({
          path:'/error',
          query:{
            redirect:this.$route.fullPath
          }
        })
      })
      return request
    },
    checkProAuth(){
      if(!this.productList.length){
        this.$alert(`您暂无专家赋值模块的产品权限`, '提示', {
          customClass:"custom-msgbox",
          confirmButtonText: '确定',
          showClose:false,
          type: 'warning'
        })
        .then(() => {
          this.$router.replace({
            path:'/'
          })
        })
      }else{
        this.curProduct=this.productList[0]||{}
      }
    },
    changeProduct(val){
      this.curProduct=val
    }
  },
  watch: {

  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  
</style>
